* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Righteous', cursive;
}

body {
    font-size: 100%;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: url(../imgs/Karasuno-background.png);
    
}

.cabecalho {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    overflow: visible;
}

.cabecalho-logo {
    display: block;
    flex-direction: row;
    align-items: center;
    width: 15vw;
    margin-left: 41vw;
    opacity: 0.70;
    height: auto;
    -webkit-transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
}

.cabecalho-logo:hover{
    -webkit-transform: scale(1.1);
    transform: scale(1.2);
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60%;
}

.loginregister-box {
    text-align: center;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    color: #fff;
    border-radius: 10px;
    padding: 30px 40px;
}

.loginregister-box h1{
    text-decoration: none;
    text-align: center;
    cursor: pointer;
}

.loginregister-box a h1 {
    font-size: 36px;
    color: #fff;
}

.loginregister-box a i {
    position: relative;
    cursor: pointer;
    right: 8.5rem;
    margin-top: 0.2rem;
    color: #fff;
    font-size: 33px;
}


.input-box {
    position: relative;
    width: 100%;
    height: 50px;
    background: transparent;
    margin: 30px 0;
}

.input-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .2);
    border-radius: 40px; 
    font-size: 16px;
    color: #fff;
    padding: 20px 45px 20px 20px;
}

.input-box input::placeholder {
    color: #fff;
}

.input-box i {
    position: absolute;
    cursor: pointer;
    right: 15px;
    top: 30%;
    bottom: 50%;
    transform: translateY(-50%);
    font-size: 28px;
}

.loginregister-box .remember-forgot {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: -15px 0 15px;
}

.remember-forgot label input{
    accent-color: #fff;
    margin-right: 3px;
}

.remember-forgot a {
    color: #fff;
    text-decoration: none;
}

.remember-forgot a:hover {
    text-decoration: underline;
}

.btn-login2, .btn-login2 i {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2rem;
}

.loginregister-box .btn-login {
    width: 100%;
    height: 45px;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0 ,0 , .1);
    cursor: pointer;
    font-size: 16px;
    color: #131313;
    opacity: 0.85;
    background-color: rgb(254, 164, 8);
    font-weight: 600;
    -webkit-transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
}

.loginregister-box .btn-login:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.07);
}

.loginregister-box .register-link {
    font-size: 14.5px;
    text-align: center;
    margin-top: 20px 0 15px;
}

.register-link p {
    padding-top: 1vw;
    color: #fff;
    font-weight: 600;
}

.register-link a {
    font-weight: 600;
    color: #fff;
    text-decoration: none;
}

.register-link a:hover {
    text-decoration: underline;
}

.loginregister-box .btn-login2 {
    width: 50%;
    height: 45px;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0 ,0 , .1);
    cursor: pointer;
    font-size: 16px;
    color: #131313;
    opacity: 0.85;
    background-color: rgb(254, 164, 8);
    font-weight: 600;
    -webkit-transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
}

.loginregister-box .btn-login2:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.07);
}
