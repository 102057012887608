.right-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 42rem;  
    padding-top: 4rem;
    overflow: visible;
}

.right-image {
    width: 10vw;
    padding: 15px;
    display: block;
    -webkit-transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
}

.right-image:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.25);
}



@media screen and (max-width:1500px) {
    .right-content {   
        padding-right: 100%;
    }
    
}

@media screen and (max-width:1400px) {
    .right-content {   
        display: none;
    }
    
}

