* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Righteous', cursive;
}

body {
    font-size: 100%;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: url("../imgs/Karasuno-background.png");
}
.cabecalho {
    display: flex;
    flex-direction: row;
    align-items: right;
    padding: 20px;
    overflow: visible;
}

.main-content {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: row;
    
}

/* Responsive */










