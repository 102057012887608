.left-content{
    margin-left: 2rem;
    padding-bottom: 14rem;
    width: 40rem;
    display: block;
    overflow: visible;
}

.left-content-title {
    color: rgb(253,118,0);
    font-family: 'Righteous', cursive;
    font-size: 6vw;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.87;
    padding-right: 4rem;
    padding-bottom: 1rem;
    cursor: pointer;
    -webkit-transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
}

.left-content-title:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.left-content > h2 {
    color: rgb(255, 255, 255);
    font-family: 'Righteous', cursive;
    font-size: 1.5vw;
    padding-top: 5px;
    display: block;
}


@media screen and (max-width:1400px) {
    .main-content {
        justify-content: center;
        text-align: center;
        align-items: center;
        padding-top: 8rem;
    }
    .left-content-title {
        padding-left: 2rem;
    }
    
}

@media screen and (max-width:850px) {
    .left-content > h2{
        font-size: 1rem;
        margin: 0 4rem;

    }
    
}
