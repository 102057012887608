.user_button {
    position: relative;
    display: flex;
    margin-left: 26rem;
    background: transparent;
    backdrop-filter: blur(2px);
    border: 3px solid rgb(190, 82, 43);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    border-radius: 50%;
    -webkit-transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    cursor: pointer;
}
.user_image {
    width: 4rem;
}

.user_button:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);

}

@media screen and (max-width:1230px) {
    .user_button {
        margin-left: 20rem;
    }
}

@media screen and (max-width:1000px) {
    .user_button {
        margin-left: 16rem;
    }
}


@media screen and (max-width:850px) {
    .user_button {
        display: none;
    }
}


